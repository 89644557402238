import { FC } from 'react';

import { useTranslation } from '@cms/i18n';
import { Competition } from '@common/clients/api';
import { InternalLink, Route } from '@web/atoms/InternalLink';
import { ResponsiveToggle } from '@web/atoms/ResponsiveToggle';

import styles from './MatchList.module.scss';

interface Props {
    competition: Competition;
}

export const CompetitionElement: FC<Props> = ({ competition }) => {
    const __common = useTranslation('common').t;
    return (
        <li className={styles.competition}>
            <h2>{competition.tag?.title || competition.name}</h2>
            {competition.tag ? (
                <InternalLink route={Route.Competition} query={{ tag: competition.tag.tagSlug }}>
                    <ResponsiveToggle
                        desktop={__common('more-x', { x: competition.tag.title })}
                        mobile={__common`more`}
                    />
                    <span className="f-icon right-icon"></span>
                </InternalLink>
            ) : null}
        </li>
    );
};
